<template>
    <v-container fluid
    >
        <v-row>
            <v-col cols="12">
                <v-card style="margin-bottom: 50px;">
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12">
                                            <h5>Info Toko</h5>
                                        </v-col>
                                    </v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                            >
                                                <v-text-field
                                                    label="Nama Toko"
                                                    v-model="name_customer"
                                                ></v-text-field>
                                                <v-text-field
                                                    label="Nama Pemilik Toko"
                                                    v-model="name_pemilik_toko"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12" xs="12" sm="12" md="8"
                                            >
                                                <v-text-field
                                                    label="Alamat Toko"
                                                    v-model="address_customer"
                                                    
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="12" md="2"
                                            >
                                                <v-text-field
                                                    label="RT"
                                                    v-model="rt"
                                                    
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="12" md="2"
                                            >
                                                <v-text-field
                                                    label="RW"
                                                    v-model="rw"
                                                    
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12" xs="12" sm="12" md="3"
                                            >
                                                <v-text-field
                                                    label="Kel"
                                                    v-model="kelurahan"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="12" md="3"
                                            >
                                                <v-text-field
                                                    label="Kec"
                                                    v-model="kecamatan"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="12" md="3"
                                            >
                                                <v-text-field
                                                    label="Kota"
                                                    v-model="city_customer"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="12" md="3"
                                            >
                                                <v-text-field
                                                    label="Propinsi"
                                                    v-model="propinsi"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-container>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="6">
                                <v-container fluid>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        >
                                            <v-checkbox
                                                v-model="activity"
                                                label="Penawaran Aplikasi"
                                                color="red"
                                                value="penawaranAplikasi"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="activity"
                                                label="Unduh Aplikasi"
                                                color="red darken-3"
                                                value="unduhAplikasi"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="activity"
                                                label="Pendaftaran Mitra"
                                                color="indigo"
                                                value="pendaftaranMitra"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="activity"
                                                label="Unggah Foto Produk"
                                                color="indigo darken-3"
                                                value="unggahFotoProduk"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="activity"
                                                label="Penawaran Program Promosi Mitra"
                                                color="orange"
                                                value="penawaranProgramPromosiMitra"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="activity"
                                                label="Pemasangan Promotion Tools"
                                                color="orange darken-3"
                                                value="pemasanganPromotionTools"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>
                                        <!-- to be added later upload foto produk -->
                                        <v-col
                                            cols="12" style="margin-top: 10px;"
                                        >
                                            <v-textarea
                                                outlined
                                                name="input-7-4"
                                                label="Remark"
                                                v-model="remark"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col 
                                            cols="12" style="margin-top: -30px;"
                                        >
                                            <v-file-input
                                                v-model="file"
                                                label="Picture file"
                                                small-chips
                                                counter
                                                multiple
                                                show-size
                                                prepend-icon="mdi-image"
                                                accept="image/*"
                                                @change="handleFileUpload"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="6">
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Garis Bujur"
                                                v-model="longitude"
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Garis Lintang"
                                                v-model="latitude"
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Alamat Saat Ini"
                                                v-model="current_address"
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn block class="rounded-xl" color="primary" @click="getLocation()">Lokasi Saat Ini</v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <div id="mapCanvas" style="height:340px; width:100%; border: 1px solid grey;"></div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="12">
                                <v-btn v-if="$store.getters.UsernameUser === salesid" block class="rounded-xl" color="success" @click="saveActivity()">Simpan Aktivitas</v-btn>
                                <v-btn v-else block class="rounded-xl" color="success" disabled>Simpan Aktivitas</v-btn>
                            </v-col>
                            <v-dialog
                                v-model="dialog"
                                class="gg"
                                persistent
                                width="400"
                                >
                                <v-card
                                    color="primary"
                                    dark
                                >
                                    <v-card-text>
                                    Please stand by
                                    <v-progress-linear
                                        indeterminate
                                        color="white"
                                        class="mb-0"
                                    ></v-progress-linear>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-row>
                    </v-container>
                </v-card>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            salesData: [],
            customers: [],
            customer: '',
            
            name_customer: '',
            name_pemilik_toko:'',
            address_customer: '',
            rt:'',
            rw:'',
            kelurahan:'',
            kecamatan:'',
            city_customer: '',
            propinsi:'',
            activity: [],
            remark: '',
            longitude: '',
            latitude: '',
            current_address: '',
            salesid: '',
            officeId:'',

            data_var: {
                entity_id : 'TOBANG',
                appl_id : 'DASHBOARD'
            },
            
            dialog: false,
            mymap: '',
            loadingUpload: false,
            uploadFiles: [],
            file: [],
            desc_file:'',
        }
    },
    mounted(){
        // this.getEnvConf()
        this.getSalesData();
        // this.dialogUploadFile = true;
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                if(res.data.data != null){
                    for (let i = 0; i < res.data.data.length; i++) {

                        if (res.data.data[i]['var_id'] === 'SALESID') {
                            if (res.data.data[i]['var_value'] != null) {
                                this.salesid = res.data.data[i]['var_value']
                            }
                        }

                    }
                }

            })
        },
        async getSalesData(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/tobang/recruiter?sales_id=${this.$store.state.user.username}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.salesData = res.data.data[0];
                this.salesid = this.salesData.sales_id;
                this.officeId = this.salesData.office_id;
            });
        },
        getLocation(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition);
            } else { 
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please activate GPS",
                    visible: true
                };
            }
        },
        async showPosition(position){
            console.log(position);
            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }
            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`)
            .then(res => {
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;
                this.current_address = address
                this.longitude = res.data.lon
                this.latitude = res.data.lat

                this.mymap = L.map('mapCanvas').setView([position.coords.latitude, position.coords.longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_TOBANG}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([position.coords.latitude, position.coords.longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your current location</b><br>'+address)
                    .openPopup();

                    });
        },
        // berubah validasi saja

        // async showPosition(position){

        //     this.$store.dispatch('setOverlay', true)

        //     if (this.mymap != '') {
        //         this.mymap.off()
        //         this.mymap.remove()
        //     }
        //     this.latitudeModel = position.coords.latitude
        //     this.longitudeModel = position.coords.longitude
        //     await axios.get(`${process.env.VUE_APP_URL}/api/tobang/sales_activity/getAddress?lat=${this.latitudeModel}&lon=${this.longitudeModel}`, { 
        //         headers: { 
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //             'Access-Control-Allow-Origin': '*',
        //             Authorization: `Bearer ${this.$store.getters.isTokenUser}`
        //         } 
        //     })
        //     .then(res => {

        //         this.currentLocationModel = res.data.features[0].place_name

        //     })
        //     .catch(err => {

        //         this.$store.dispatch('setOverlay', false)
        //         if(err.response.status == '401'){
        //             this.$router.push('/');
        //         }

        //     })

        //     this.mymap = L.map('mapCanvas').setView([this.latitudeModel, this.longitudeModel], 18);

        //     L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_TOBANG}`, {
        //         maxZoom: 18,
        //         attribution: '<b>Your current location</b><br> ' + this.currentLocationModel,
        //         id: 'mapbox/streets-v11',
        //         tileSize: 512,
        //         zoomOffset: -1
        //     }).addTo(this.mymap)

        //     L.marker([this.latitudeModel, this.longitudeModel],
        //         {draggable: false,     
        //         //title: 'Hover Text',
        //         opacity: 0.75}
        //         )
        //         .addTo(this.mymap)
        //         .bindPopup('<b>Your current location</b><br> ' + this.currentLocationModel)
        //         .openPopup()

        //     this.$store.dispatch('setOverlay', false)

        //     },
        async saveActivity(){
            this.dialogUploadFile = true;

            if (this.file.length === 0 || this.name_customer === '' || this.name_pemilik_toko === '' || this.address_customer === '' ||
                this.rt === '' || this.rw === '' || this.kelurahan === '' || this.kecamatan === '' ||
                this.city_customer === '' || this.propinsi === '' || this.activity === [] ||
                this.remark === '' || this.file === []
                // ||  this.longitude === '' || this.latitude === '' || this.current_address === '' 
                || this.salesid === '' || this.officeId === ''
                ) {
                this.dialog = false
                // console.log(this.file.length)
                // console.log(this.name_customer)
                // console.log(this.name_pemilik_toko)
                // console.log(this.address_customer)
                // console.log(this.rt)
                // console.log(this.rw)
                // console.log(this.kelurahan)
                // console.log(this.kecamatan)
                // console.log(this.city_customer)
                // console.log(this.propinsi)
                // console.log(this.activity)
                // console.log(this.remark)
                // console.log(this.file)
                // console.log(this.salesid)
                // console.log(this.officeId)
                return this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "please fill in all data",
                    visible: true
                };
            } else{
                if (this.$store.getters.UsernameUser === this.salesid) {
                    this.dialog = true
                    if(this.longitude != '' || this.latitude != '' || this.current_address != '' ){
                        this.mymap.off();
                        this.mymap.remove();
                    }
                    await axios.get(`${process.env.VUE_APP_URL}/api/tobang/sales_activity/checkLastVisit`, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    })
                    .then(res => {
                        if (res.data.data <= 0) {
                            this.storeSalesActivity()
                        } else {
                            this.dialog = false

                            return this.snackbar = {
                                                    color: "error",
                                                    icon: "mdi-alert-circle",
                                                    mode: "multi-line",
                                                    position: "top",
                                                    timeout: 7500,
                                                    title: "Error",
                                                    text: "Please wait 15 minutes after last customer visit.",
                                                    visible: true
                                                };
                        }
                    });
                } else {
                    this.dialog = false
                    return this.snackbar = {
                                                color: "error",
                                                icon: "mdi-alert-circle",
                                                mode: "multi-line",
                                                position: "top",
                                                timeout: 7500,
                                                title: "Error",
                                                text: "you don't have access",
                                                visible: true
                                            };
                }
            }
        },
        async storeSalesActivity(){
            // this.latitude = '111'
            // this.longitude = '222'
            // this.current_address = 'jalan sementara'
            var new_visit = 1;
            await axios.post(`${process.env.VUE_APP_URL}/api/tobang/sales_activity/store`, {
                'salesid': this.salesid ? this.salesid : '',
                'customerid': this.customer ? this.customer : '',
                'namacustomer': this.name_customer,
                'nama_owner': this.name_pemilik_toko,
                'alamat': this.address_customer,
                'rt': this.rt,
                'rw': this.rw,
                'kel': this.kelurahan,
                'kec': this.kecamatan,
                'city': this.city_customer,
                'propinsi': this.propinsi,
                'sales_latitude': this.latitude,
                'sales_longitude': this.longitude,
                'lt_location' : this.current_address,
                'remark' : this.remark ? this.remark : '',
                'activities': this.activity,
                'sales_id' : this.salesid,
                'office_id' : this.officeId,
                'new': new_visit,
                'justid' : 'Y'
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.saveFilePdf(res.data.data)
                this.dialog = false
                this.customer = ''
                this.name_customer = ""
                this.name_pemilik_toko = ""
                this.address_customer = ''
                this.rt = ""
                this.rw = ""
                this.kelurahan = ""
                this.kecamatan = ""
                this.city_customer = ''
                this.propinsi = ""
                this.activity = []
                this.remark = ''
                this.latitude = ''
                this.longitude = ''
                this.current_address = ''
                // this.salesid = ""
                // this.officeId = ""

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Create Activity",
                    visible: true
                };
            }).catch(err => {
                // console.log(err)
                this.dialog = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        handleFileUpload(value){
            // this.file = value;
            // console.log(this.file);
        },
        saveFilePdf(value){
            let formData = new FormData()
            formData.append('mill_id', value.mill_id);
            formData.append('tr_id', value.tr_id);
            formData.append('salesid', value.salesid);
            formData.append('customerid', value.customerid);
            formData.append('deskripsi', '');
            for( var i = 0; i < this.file.length; i++ ){
                let file = this.file[i];
                formData.append('file[' + i + ']', file);
            }
            axios.post(`${process.env.VUE_APP_URL}/api/tobang/sales_activity/upload_file`, formData ,
            { 
                headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
            }).then(res => {
                // console.log(res);
                this.file = []
                // this.doc_file = ''
                // this.snackbar = {
                //     color: "success",
                //     icon: "mdi-checkbox-marked-circle",
                //     mode: "multi-line",
                //     position: "top",
                //     timeout: 7500,
                //     title: "Success",
                //     text: "Successfully Insert picture",
                //     visible: true
                // };
            }).catch(err => {
                // console.log(err)
                this.loadingUpload = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
    },
    watch: {
    }
}
</script>
<style scoped>
.v-dialog {
    top: 181px !important;
    position: absolute !important;
    border-radius: 100px;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}
.gg {
    top: 181px !important;
    position: absolute !important;
    border-radius: 4px;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}
</style>