<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-card>
        <v-col cols="12">
            <v-tabs
                v-model="tab"
                centered
                color="primary"
              
            >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                    Existing Mitra
                    <v-icon>mdi-sticker-emoji</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                    New Mitra
                    <v-icon>mdi-new-box</v-icon>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    value="tab-1"
                >
                    <customer-visit-existing />
                </v-tab-item>
                <v-tab-item
                    value="tab-2"
                >
                    <customer-visit-new />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
        </v-card>
      </v-row>      
    </v-container>
</template>
<script>
  import CustomerVisitExisting from './CustomerVisitExisting.vue'
  import CustomerVisitNew from './CustomerVisitNew.vue'
  
  export default {
      components:{
        CustomerVisitExisting,
        CustomerVisitNew
    },
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'TOBANG',
                disabled: false,
                href: '/admin/tobang',
                },
                {
                text: 'Mitra Recruiter',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Mitra Visit',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            tab: 'tab-1'
        }
    },
    mounted(){
        
    },
    methods:{
        
    },
    watch: {
    }
  }
</script>